<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card class="mx-auto">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="searchInvoiceNumber"
                ref="searchInvoiceNumberInput"
                :label="$vuetify.lang.t('$vuetify.searchDocument')"
                :placeholder="$vuetify.lang.t('$vuetify.searchDocument')"
                :disabled="isSearching"
                single-line
                hide-details
                clear-icon="fal fa-times"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="searchInvoiceDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchInvoiceDate"
                    :label="$vuetify.lang.t('$vuetify.saleDocumentDate')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchInvoiceDate"
                  :disabled="isSearching"
                  no-title
                  scrollable
                  @input="handleDateSelect"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-icon medium right @click="applyDocumentsSearchByInvoiceNr">fal fa-search</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
    <div class="flex-grow-1 d-flex justify-center mt-5">
      <v-progress-circular v-if="isSearching" color="primary" indeterminate size="64"></v-progress-circular>
    </div>
    <v-flex xs12>
      <v-card
        class="pa-1 my-2"
        v-for="(item, i) in documents"
        :key="i"
        :color="item.status === 1 ? 'grey lighten-4' : ''"
      >
        <v-card-title>{{ item.documentNumber }}</v-card-title>

        <v-card-text>
          <div class="text--primary">{{ item.dateHour }}</div>
          <div class="text--primary">
            {{ $vuetify.lang.t("$vuetify.total") }} {{ item.totalAmount }} {{ currencySymbol }}
          </div>
          <div class="text--primary">{{ $vuetify.lang.t("$vuetify.salesmenName") }} {{ item.salesmanName }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="item.docEnablesReturn && hasSelectedSalesmanAccessToSaleReturn"
            @click="generateCreditNote(item)"
            color="red"
            text
          >
            {{ $vuetify.lang.t("$vuetify.returnDocument") }}
          </v-btn>
          <v-btn
            v-if="item.docEnablesCancel && hasSelectedSalesmanAccessToSaleCancel"
            @click="generateCancelDocument(item, false)"
            color="red"
            text
          >
            {{ $vuetify.lang.t("$vuetify.cancelDocument") }}
          </v-btn>
          <v-btn v-if="item.docNature === 'OR'" @click="setSaleToCart(item)" color="blue lighten-2" text>
            {{ $vuetify.lang.t("$vuetify.resumeSale") }}
          </v-btn>
          <v-btn @click="printDocument(item, 'sale')" color="blue" text>
            {{ $vuetify.lang.t("$vuetify.print") }}
          </v-btn>
          <v-btn v-if="item.docEnablesPrintOffer" @click="printDocument(item, 'offer')" color="blue" text>
            {{ $vuetify.lang.t("$vuetify.offerDocument") }}
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn icon @click="changeShow(i)">
            <v-icon small>{{ show[i] ? "fas fa-arrow-up" : "fas fa-arrow-down" }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show[i]">
            <v-divider></v-divider>
            <v-card-text>
              <ItemsList :items="item.items" :enableEditions="false" :enableDialogEdition="false"></ItemsList>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-dialog v-model="displayDocument" persistent max-width="400px">
        <DisplayPDF v-if="displayDocument" :source="documentSource" @close-pdf="closeDocument"></DisplayPDF>
      </v-dialog>

      <v-dialog v-model="displayDialogNrCopiesPrint" persistent max-width="300px">
        <v-card>
          <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.offerDocument") }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="numberCopiesPrint"
              :label="$vuetify.lang.t('$vuetify.numberOfCopies')"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelPrintDocument"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
            <v-btn class="primary" text @click="printDocument(null, 'offer', 1)"
              >{{ $vuetify.lang.t("$vuetify.print") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="displayDialogConfirmCancelDocument" persistent max-width="300px">
        <ConfirmAction
          :title="confirmConfirmCancelDocumentTitle"
          :confirmMessage="confirmConfirmCancelDocumentMessage"
          :displayCancelButton="true"
          @close-dialog="displayDialogConfirmCancelDocument = false"
          @confirm-action="generateCancelDocument(null, true)"
        ></ConfirmAction>
      </v-dialog>
    </v-flex>
    <mugen-scroll :handler="getNextDocuments" :should-handle="!loading && hasMoreResults">
      <!-- Verifying the flag which defines whether or not there are more results to load -->
      <p v-if="loading">...</p>
    </mugen-scroll>
  </v-layout>
</template>

<script>
import MugenScroll from "vue-mugen-scroll";
import { mapGetters } from "vuex";
import {
  getUrlParams,
  getErrorMessage,
  getApiBaseUrl,
  getApiBaseStore,
  PERM_SALE_RETURN,
  PERM_SALE_CANCEL,
  isValidSaleDocType,
  getDocumentPrintPageSize,
} from "@/_helpers";
import { salesServices } from "@/services";
import ItemsList from "@/components/ItemsList.vue";
import DisplayPDF from "@/components/DisplayPDF.vue";
import ConfirmAction from "@/components/ConfirmAction.vue";

export default {
  name: "SalesHistory",
  components: { MugenScroll, ItemsList, DisplayPDF, ConfirmAction },
  data: () => ({
    //The variable which stores a list of Documents to be displayed on this View
    documents: null,
    //This variable is a list which has as many elements as the previous list. For each element, it stores whether the
    // corresponding document is expanded. Expanding the Document's section allows checking its details.
    show: [],
    //Variables used on the Search section
    searchInvoiceNumber: null,
    searchInvoiceDate: null,
    menu: false,
    // modal: false,
    //Variables used on the feature of retrieving more Documents from the API
    loading: false,
    hasMoreResults: true,
    nextPageUrl: null,
    //Variables used by the buttons available on the section of each Document: return, print and print offer
    displayDialogConfirmCancelDocument: false,
    documentToCancel: false,
    confirmConfirmCancelDocumentTitle: null,
    confirmConfirmCancelDocumentMessage: null,
    displayDocument: false,
    documentSource: null,
    displayDialogNrCopiesPrint: false,
    numberCopiesPrint: null,
    isSearching: false,
  }),
  methods: {
    handleDateSelect(date) {
      this.menu = false;
      this.$refs.menu.save(date);
    },
    changeShow(index) {
      this.show[index] = !this.show[index];
      this.$forceUpdate();
    },
    focusSearchDocumentInput() {
      this.$refs.searchInvoiceNumberInput.focus();
    },
    /**
     * The Sales' Documents Search can:
     * 1 - or lookup for documents with a similar Document Number
     * In this case, the filter Date is also used and the Documents must belong to a specific Store,
     * instead of searching on all the Sale Documents of the Company
     * 2 - lookup for documents with the exact Document Number
     * 3 - automatically search the last 5 Sale Documents, of a specific Store
     */
    applyDocumentsSearchByInvoiceNr() {
      if (this.isSearching) return;

      this.isSearching = true;
      //1 - or lookup for documents with a similar Document Number
      // In this case, the filter Date is also used and the Documents must belong to a specific Store,
      // instead of searching on all the Sale Documents of the Company
      if (this.searchInvoiceNumber || this.searchInvoiceDate) {
        let params = {};
        if (this.searchInvoiceDate) {
          params = {
            date: this.searchInvoiceDate,
            store: getApiBaseStore(),
          };
          if (this.searchInvoiceNumber) {
            params["InvoiceSearch"] = this.searchInvoiceNumber.trim();
          }
        }
        //2 - lookup for documents with the exact Document Number
        else {
          params = { InvoiceNo: this.searchInvoiceNumber.trim() };
        }

        //The searched values must be cleared
        this.searchInvoiceNumber = null;
        this.searchInvoiceDate = null;

        this.applyDocumentsSearch(params, true);
      }
      //3 - automatically search the last 5 Sale Documents, of a specific Store
      else {
        this.applyDocumentsSearchByLastFive();
      }
    },
    /**
     * This Sales' Documents search is used automatically when the User accesses the Search Interface.
     * It aims at displaying the last 5 Sale Documents emitted on the Store the User is performing the Search.
     * This automatic Search differs from the "normal" Search since it displays the Documents ordered from the most
     * recent.
     */
    applyDocumentsSearchByLastFive() {
      let params = { LastestFirst: 1, page_size: 5, store: getApiBaseStore() };

      this.applyDocumentsSearch(params, false);
    },
    /**
     * Calls the Service to perform the Sales' Search and deals with the possibility of having multiple pages of results
     *
     * @param parameters The filters to get the desired Sale Documents
     * @param validateHasMoreResults A flag to indicate if after getting the first page of Sale Documents results,
     *  it is necessary to verify if there are more pages with results.
     */
    applyDocumentsSearch(parameters, validateHasMoreResults) {
      this.documents = [];
      salesServices.getAll(parameters).then(
        (result) => {
          if (validateHasMoreResults) {
            this.nextPageUrl = result.next;
            this.hasMoreResults = this.nextPageUrl !== undefined;
          } else {
            this.hasMoreResults = null;
          }

          this.organizeSearchResults(result.results);
          this.isSearching = false;
          this.$store.dispatch("loader/setLoading", false);
        },
        (error) => {
          this.isSearching = false;
          getErrorMessage(error, "Getting Sale Search Results", this);
        }
      );

      if (this.documents) {
        for (let i = 0; i < this.documents.length; i++) {
          this.show.push(false);
        }
      }
    },
    getNextDocuments() {
      //Setting the loading flag to true
      this.loading = true;
      if (this.nextPageUrl !== null) {
        this.$store.dispatch("loader/setLoading", true);
        salesServices.getAll(getUrlParams(this.nextPageUrl)).then(
          (result) => {
            this.loading = false;
            this.nextPageUrl = result.next;
            this.hasMoreResults = this.nextPageUrl !== undefined;
            if (result.results) {
              this.organizeSearchResults(result.results);

              this.$store.dispatch("loader/setLoading", false);
            }
          },
          (error) => {
            getErrorMessage(error, "Getting the Items", this);
          }
        );
      } else this.loading = false;
    },
    /**
     * For the given list of Sales' Documents this function organizes the data to add it to the this.documents variable
     * which is used to display the information.
     *
     * @param documents A list of Sales' Documents, exactly as they are received from the API
     */
    async organizeSearchResults(documents) {
      let currentdate = new Date();
      let month = "" + (currentdate.getMonth() + 1);
      let day = "" + currentdate.getDate();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      let date = currentdate.getFullYear() + "-" + month + "-" + day;

      //1 - iterating through Documents received from the API
      for (var i = 0; i < documents.length; i++) {
        let saleItems = [];
        let saleItemsMain = [];
        let menuItems = [];
        let componentsItems = [];

        //2 - for each Document, analysing its Details and filtering the ones which are not
        // 2.1 Menu components nor
        // 2.2 Observation Items
        documents[i].SaleTransactionDetails.forEach(function (documentDetail) {
          if (documentDetail.ItemId === documentDetail.ParentItemId) {
            saleItemsMain.push(documentDetail);
          }
        });

        //3 - iterating through the Sales' Details identified above
        for (var j = 0; j < saleItemsMain.length; j++) {
          //3.1 - for each Detail, identifying the Sales' Details which may be linked to the "main" Detail
          //Here we have decided to maintain the structure that is being used on the ItemsList Component.
          //The Items must contain:
          // 3.2 an Array of "Menu Items" (menu_items)
          // 3.3 an Array of "Components" (Items of type Extra and Information) (components)
          menuItems = { 0: { items: [] } };
          // @modified ana.castro  obj.ParentId === j+2 It is necessary to add this condition.
          // This is not perfect but it is what we can implement. If in the same Document we have the same Menu more
          // than once, to distinguish the components of each menu, it is necessary to use the ParentId.
          // However, the Main Menu Item, does not have any reference to this value which enables linking the Menu and
          // its components. Therefore, we have to trust that we receive the Items in order and use the counter of the
          // iteration to determine the ParentId.
          //Why the "+2"? This cycle counter starts with 0. The API counter starts with 1. And the ParentId value
          // does not represent the "line number" of the Main Menu Item. It represents the line number of the first Menu
          // component.
          let mainItemChildren = documents[i].SaleTransactionDetails.filter((obj) => {
            return (
              obj.ParentId === j + 2 &&
              obj.ParentItemId === saleItemsMain[j].ItemId &&
              obj.ItemId !== saleItemsMain[j].ItemId
            );
          });

          for (var k = 0; k < mainItemChildren.length; k++) {
            if (
              mainItemChildren[k].ParentItemId === saleItemsMain[j].ItemId &&
              mainItemChildren[k].ItemId !== saleItemsMain[j].ItemId
            ) {
              if (mainItemChildren[k].ItemType === 2 || mainItemChildren[k].ItemType === 7) {
                let component = {
                  description: mainItemChildren[k].Description,
                  // item_unit_price: el.TaxIncludedPrice.toFixed(2),
                  item_unit_price: mainItemChildren[k].TotalTaxIncludedAmount.toFixed(2),
                  code: mainItemChildren[k].ItemId,
                  id: mainItemChildren[k].Item,
                };
                componentsItems.push(component);
              } else {
                let menuItem = {
                  description: mainItemChildren[k].Description,
                  // item_unit_price: el.TaxIncludedPrice.toFixed(2),
                  item_unit_price: mainItemChildren[k].TotalTaxIncludedAmount.toFixed(2),
                  code: mainItemChildren[k].ItemId,
                  id: mainItemChildren[k].Item,
                };
                menuItems[0]["items"].push(menuItem);
              }
            }
          }

          saleItems.push({
            item_id: saleItemsMain[j].Item,
            item_code: saleItemsMain[j].ItemId,
            item_name: saleItemsMain[j].Description,
            // item_unit_price: saleItemsMain[j].TaxIncludedPrice.toFixed(2),
            item_unit_price:
              saleItemsMain[j].ItemType === 6
                ? saleItemsMain[j].TotalTaxIncludedAmount.toFixed(2)
                : saleItemsMain[j].TaxIncludedPrice.toFixed(2),
            item_image: null,
            item_family: saleItemsMain[j].ItemFamilyId,
            item_parent_id: saleItemsMain.Id !== saleItemsMain.ParentItemId ? saleItemsMain[j].ParentItemId : null,
            quantity: saleItemsMain[j].Quantity,
            discount: saleItemsMain[j].DiscountPercent,
            discount_type: "percentage",
            line_total_price: saleItemsMain[j].TotalTaxIncludedAmount,
            menu_items: menuItems,
            components: componentsItems,
          });
        }

        const validSaleDocType = isValidSaleDocType(documents[i].TransNature);
        const printPageSize = await getDocumentPrintPageSize(documents[i].TransNature);
        this.documents.push({
          dateHour: documents[i].InvoiceTime.replace("T", " "),
          totalAmount: documents[i].TotalAmount.toFixed(2),
          salesmanName: documents[i].SalesmanName,
          items: saleItems,
          customer: documents[i].LoyaltyCardId,
          taxNumber: documents[i].CustomerTaxId,
          documentNumber: documents[i].InvoiceNo,
          creditDocumentNumber: documents[i].CreditNo,
          docNature: documents[i].TransNature,
          //@modified ana.castro 2021.07.08 SABO-2011
          docEnablesReturn:
            documents[i].InvoiceStatus !== 1 &&
            validSaleDocType &&
            !documents[i].SelfExpenditure &&
            (documents[i].TotalAmountCredit < documents[i].TotalAmount || documents[i].TotalAmount === 0),
          docEnablesCancel:
            documents[i].InvoiceStatus !== 1 &&
            validSaleDocType &&
            !documents[i].SelfExpenditure &&
            (documents[i].TotalAmountCredit < documents[i].TotalAmount || documents[i].TotalAmount === 0) &&
            documents[i].InvoiceTime.split("T")[0] === date,
          docEnablesPrintOffer:
            documents[i].InvoiceStatus !== 1 &&
            validSaleDocType &&
            documents[i].TotalAmountCredit < documents[i].TotalAmount,
          print:
            printPageSize === "1"
              ? getApiBaseUrl() + documents[i].PrintRollUrl.substring(1)
              : getApiBaseUrl() + documents[i].PrintA4Url.substring(1),
          printOffer:
            printPageSize === "1"
              ? getApiBaseUrl() + documents[i].PrintOfferRollUrl.substring(1)
              : getApiBaseUrl() + documents[i].PrintOfferA4Url.substring(1),
          status: documents[i].InvoiceStatus,
        });
      }
    },
    generateCreditNote(sale) {
      this.$store.dispatch("cart/setCartEmpty");

      let params = { InvoiceNo: sale.documentNumber };

      //Possible values: return and returnTotal
      let creditNoteType = "return";
      //The Pos Transaction Details store a ParentId. This enables identifying the items which belong to a Menu. Since
      // these Items have all the same ParentId.
      // The first detail has a ParentId equal to 2 or No ParentId. Even though the ParentId is none, the following
      // ParentId will have the value 3.
      let lastParentId = 2;

      let saleItemsMain = [];
      let menuItems = [];
      let itemComponent = [];

      salesServices.getAll(params).then(
        (result) => {
          let document = result.results[0];
          //2 - for each Document, analysing its Details and filtering the ones which are not
          // 2.1 Menu components nor
          // 2.2 Observation Items
          document.SaleTransactionDetails.forEach(function (documentDetail) {
            if (
              documentDetail.ItemId === documentDetail.ParentItemId &&
              documentDetail.TotalQuantityCredit < documentDetail.Quantity
            ) {
              saleItemsMain.push(documentDetail);
            }
          });

          //3 - iterating through the Sales' Details identified above
          for (var i = 0; i < saleItemsMain.length; i++) {
            this.$log.debug(
              "Generate Credit Note | Iterating through the Sale's Details | Detail: ",
              saleItemsMain[i].Description
            );
            this.$log.debug(
              "Generate Credit Note | Iterating through the Sale's Details | Detail Quantity: ",
              saleItemsMain[i].Quantity
            );
            this.$log.debug(
              "Generate Credit Note | Iterating through the Sale's Details | Detail Credit Quantity: ",
              saleItemsMain[i].TotalQuantityCredit
            );
            let quantity = saleItemsMain[i].Quantity - saleItemsMain[i].TotalQuantityCredit;

            if (saleItemsMain[i].TotalQuantityCredit < saleItemsMain[i].Quantity) {
              //3.1 - for each Detail, identifying the Sales' Details which may be linked to the "main" Detail
              //Here we have decided to maintain the structure that is being used on the ItemsList Component.
              //The Items must contain:
              // 3.2 an Array of "Components" (Items of type Extra and Information) (components)
              // 3.3 an Array of "Menu Items" (menu_items)

              //Nota: na questão dos Menus, quando há Artigos do tipo Menus iguais no mesmo documento de Venda,
              // a identificação dos componentes de cada menu está a ser feita pelo ParentItemId e também pelo ParentId,
              // uma vez que o ParentItemId é igual para todos. Não é a solução ideal mas é a possível neste momento
              // (dada a informação disponível quer na API quer no modelo de dados da PosTransactionsDetails).
              //Assim, tivemos de implementar este Try Catch para terminar o ciclo quando passamos a iterar sob um
              // registo com ParentId diferente, sinal que é um Componente pertencente a Artigo do Tipo Menu igual.
              menuItems = { 0: { items: [] } };
              let mainItemChildren = document.SaleTransactionDetails.filter((obj) => {
                return (
                  obj.ParentItemId === saleItemsMain[i].ItemId &&
                  obj.ItemId !== saleItemsMain[i].ItemId &&
                  obj.ParentId === i + 2
                );
              });
              if (mainItemChildren.length > 0) {
                this.$log.debug("Generate Credit Note | It's an Item with components");
              }
              for (var j = 0; j < mainItemChildren.length; j++) {
                if (mainItemChildren[j].ItemType === 2 || mainItemChildren[j].ItemType === 7) {
                  this.$log.debug(
                    "Generate Credit Note | It's an Item with components | Info Component: ",
                    mainItemChildren[j].Description
                  );
                  let component = {
                    description: mainItemChildren[j].Description,
                    item_unit_price: mainItemChildren[j].TotalTaxIncludedAmount.toFixed(2),
                    code: mainItemChildren[j].ItemId,
                    id: mainItemChildren[j].Item,
                  };
                  itemComponent[j] = component;
                }
                //3.3
                else {
                  creditNoteType = "returnTotal";
                  this.$log.debug(
                    "Generate Credit Note | It's an Item with components | Menu Component: ",
                    mainItemChildren[j].Description
                  );
                  let menuItem = {
                    description: mainItemChildren[j].Description,
                    item_unit_price: mainItemChildren[j].TotalTaxIncludedAmount.toFixed(2) / quantity,
                    code: mainItemChildren[j].ItemId,
                    id: mainItemChildren[j].Item,
                  };
                  menuItems[0]["items"].push(menuItem);
                }
              }
            }

            let itemId = saleItemsMain[i].Item;
            let itemCode = saleItemsMain[i].ItemId;
            let itemName = saleItemsMain[i].Description;
            let itemUnitPrice =
              saleItemsMain[i].ItemType === 6
                ? saleItemsMain[i].TotalTaxIncludedAmount
                : saleItemsMain[i].TaxIncludedPrice;
            // @modified ana.castro 2025.03.17 SAFO-111
            // let itemDiscount = saleItemsMain[i].DiscountPercent;
            // let itemDiscountType = saleItemsMain[i].DiscountPercent > 0 ? "percentage" : null;
            let itemDiscount = saleItemsMain[i].DiscountAmount;
            let itemDiscountType = saleItemsMain[i].DiscountAmount > 0 ? "value" : null;

            let itemOrder = i;
            let itemDiscountLoyalty = saleItemsMain[i].DiscountPercent;
            let itemDiscountLoyaltyType = saleItemsMain[i].DiscountPercent > 0 ? "percentage" : null;
            let itemMenu = menuItems;
            this.$store.dispatch("cart/setCartType", creditNoteType);
            this.$store.dispatch("cart/setCartTaxNumber", document.CustomerTaxId);
            this.$store.dispatch("cart/setCartRefDocument", document.InvoiceNo);
            this.$store.dispatch("cart/setCartLoyaltyCardId", document.LoyaltyCardId);

            this.$store.dispatch("cart/editElementInCart", {
              itemId,
              itemCode,
              itemUnitPrice,
              itemDiscount,
              itemDiscountType,
              itemDiscountLoyalty,
              itemDiscountLoyaltyType,
              quantity,
              itemName,
              itemMenu,
              itemComponent,
              itemOrder,
            });

            lastParentId = lastParentId + 1;
          }

          this.$router.push({ name: "shop-cart-checkout-return" });
        },
        (error) => {
          getErrorMessage(error, "Getting the Transactions", this);
        }
      );
    },
    setSaleToCart(sale) {
      //Removing the Items which may be stored on the Cart
      this.$store.dispatch("cart/setCartEmpty");
      //Iterating over the OR details and adding them to the Cart
      for (var i = 0; i < sale.items.length; i++) {
        let itemId = sale.items[i]["item_id"];
        let itemCode = sale.items[i]["item_code"];
        let itemName = sale.items[i]["item_name"];
        let itemUnitPrice = sale.items[i]["item_unit_price"];
        let itemDiscount = sale.items[i]["discount"];
        let itemDiscountType = sale.items[i]["discount"] > 0 ? sale.items[i]["discount_type"] : null;
        let quantity = sale.items[i]["quantity"];

        this.$store.dispatch("cart/editElementInCart", {
          itemId,
          itemCode,
          itemUnitPrice,
          itemDiscount,
          itemDiscountType,
          quantity,
          itemName,
        });
      }
      this.$store.dispatch("cart/setCartTaxNumber", sale.taxNumber);
      //Redirecting the user to the Catalog interface where the Cart will be displayed with the Items of the OR
      // this.$router.push({ name: "catalog", params: { zone: 0 } });
      //@modified ana.castro 2022.12.07 SAFO-47
      this.$router.push({ name: "home" });
    },
    printDocument(sale, context, confirmCopies) {
      if (context === "sale") {
        this.displayDocument = true;
        this.documentSource = sale.print;
      } else if (context === "offer" && !confirmCopies) {
        this.documentSource = sale.printOffer;
        this.numberCopiesPrint = 1;
        this.displayDialogNrCopiesPrint = true;
      } else if (context === "offer" && confirmCopies) {
        if (this.numberCopiesPrint !== 1) {
          this.documentSource = this.documentSource + "&ncopies=" + this.numberCopiesPrint;
        }
        this.displayDocument = true;
        this.numberCopiesPrint = null;
        this.displayDialogNrCopiesPrint = false;
      }
    },
    closeDocument: function () {
      this.displayDocument = false;
      this.documentSource = null;
    },
    cancelPrintDocument: function () {
      this.displayDocument = false;
      this.documentSource = null;
      this.displayDialogNrCopiesPrint = false;
      this.numberCopiesPrint = null;
    },
    /**
     * This method is used to Cancel a Sale Document (Estorno).
     * This action is only available for Sale Documents of the current day.
     * Due to the of this action, the user is asked
     * It sets the attribute InvoiceStatus to 1.
     *
     * @param sale
     * @param confirmAction A flag which determines if the confirmation dialog should be displayed.
     */
    generateCancelDocument(sale, confirmAction) {
      if (confirmAction) {
        this.$store.dispatch("loader/setLoading", true);

        this.displayDialogConfirmCancelDocument = false;

        let params = { InvoiceNo: this.documentToCancel };

        salesServices.getAll(params).then(
          async (result) => {
            let currentdate = new Date();
            let month = "" + (currentdate.getMonth() + 1);
            let day = "" + currentdate.getDate();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            let date = currentdate.getFullYear() + "-" + month + "-" + day;

            let document = result.results[0];

            if (
              isValidSaleDocType(document.TransNature) &&
              (document.TotalAmountCredit < document.TotalAmount || document.TotalAmount === 0) &&
              document.InvoiceTime.split("T")[0] === date
            ) {
              let obj = document;
              obj["InvoiceStatus"] = 1;

              const printPageSize = await getDocumentPrintPageSize(obj.TransNature);
              salesServices.setCancelSale(obj).then(
                // eslint-disable-next-line
                (result) => {
                  if (result["InvoiceStatus"] === 1) {
                    for (const obj of this.documents) {
                      if (obj.documentNumber === result.InvoiceNo) {
                        obj.docEnablesReturn = false;
                        obj.docEnablesCancel = false;
                        obj.docEnablesPrintOffer = false;
                        obj.status = 1;
                        break;
                      }
                    }
                    if (printPageSize === "1" && result["PrintRollUrl"]) {
                      this.displayDocument = true;
                      this.documentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
                    } else if (printPageSize === "2" && result["PrintA4Url"]) {
                      this.displayDocument = true;
                      this.documentSource = getApiBaseUrl() + result["PrintA4Url"].substring(1);
                    } else {
                      this.$store.dispatch("alert/success", this.$vuetify.lang.t("$vuetify.msgCancelDocument"));
                    }
                  } else {
                    this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorCancelDocument"));
                  }

                  this.$store.dispatch("loader/setLoading", false);
                },
                (error) => {
                  getErrorMessage(error, "Set Customer Purchase", this);
                }
              );
            } else {
              this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorCancelDocument"));

              this.$store.dispatch("loader/setLoading", false);
            }
          },
          (error) => {
            getErrorMessage(error, "Getting the Transactions", this);
          }
        );
      } else {
        this.documentToCancel = sale.documentNumber;
        this.displayDialogConfirmCancelDocument = true;
        this.confirmConfirmCancelDocumentTitle = this.$vuetify.lang.t("$vuetify.confirmConfirmCancelDocumentTitle");
        this.confirmConfirmCancelDocumentMessage = this.$vuetify.lang.t("$vuetify.confirmConfirmCancelDocumentMessage");
      }
    },
  },
  computed: {
    hasSelectedSalesmanAccessToSaleReturn() {
      return this.$store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_SALE_RETURN);
    },
    hasSelectedSalesmanAccessToSaleCancel() {
      return this.$store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_SALE_CANCEL);
    },
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.focusSearchDocumentInput();
    });
    // Triggering the automatic search for the last 5 Sale Documents.
    this.applyDocumentsSearchByLastFive();
  },
};
</script>

<style scoped></style>
