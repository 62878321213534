<template>
  <v-row justify="space-between">
    <v-col cols="12" md="4">
      <v-select
        :label="labelPaymentMethod"
        v-model="paymentMethodValueClone.method"
        :items="paymentMethods"
        :item-text="(obj) => obj['description']"
        :item-value="(obj) => obj['id']"
        v-on:change="changedPM"
      ></v-select>
    </v-col>
    <!-- @modified ana.castro 2021.09.14 SABO-2147-->
    <v-col cols="12" md="4" v-if="isEmitVoucher && cartType !== 'return'">
      <v-text-field
        v-model="paymentMethodValueClone.voucherCode"
        :label="$vuetify.lang.t('$vuetify.code')"
        v-on:keyup="submitSaleSearch"
        :append-icon="'fal fa-search'"
        @click:append-outer="applySaleSearch"
        @click:append="applySaleSearch"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-text-field
        v-model="paymentMethodValueClone.value"
        :label="$vuetify.lang.t('$vuetify.valuePay')"
        type="number"
        required
        :disabled="disableValueInput || (isEmitVoucher && cartType !== 'return') || cartType === 'return'"
        :suffix="currencySymbol"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-card-actions>
        <v-btn v-if="drawMinus" @click="removePaymentMethod" color="error" icon right>
          <v-icon>fal fa-times</v-icon>
        </v-btn>
        <v-btn v-if="drawPlus" @click="addPaymentMethod" color="primary" icon right>
          <v-icon>fal fa-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { salesServices } from "@/services";
import { getErrorMessage } from "@/_helpers";

export default {
  name: "PaymentMethods",
  props: {
    paymentMethodValue: Object,
    paymentMethods: Array,
    index: Number,
    drawMinus: Boolean,
    drawPlus: Boolean,
    disableValueInput: Boolean,
    labelPaymentMethod: String,
    cartType: String,
  },
  data() {
    return {
      paymentMethodValueClone: JSON.parse(JSON.stringify(this.paymentMethodValue)),
      localDisableValueInput: false,
      localPaymentMethodValue: 0,
    };
  },
  methods: {
    /**
     * This function looks up for a Sale Document with the given Document Identifier.
     * The Document Identifier is inserted "attached" to a Payment Method, therefore its value is stored
     * in this.paymentMethodValueClone.voucherCode.
     * @modified ana.castro 2021.09.14 SABO-2147
     **/
    applySaleSearch() {
      if (this.paymentMethodValueClone.voucherCode) {
        let parameters = {
          InvoiceNo: this.paymentMethodValueClone.voucherCode.trim(),
        };
        salesServices.getAll(parameters).then(
          (result) => {
            if (result.results && result.results[0] && result.results[0].Voucher) {
              let voucherData = result.results[0].Voucher;
              let currentDate = new Date(Date.now());
              let expireDateSplit = voucherData.ExpirationDate.split("-");

              let expireDate = new Date(expireDateSplit[0], expireDateSplit[1] - 1, expireDateSplit[2]);

              if (expireDate < currentDate) {
                this.$store.dispatch(
                  "alert/error",
                  this.$vuetify.lang.t("$vuetify.msgExpiredVoucher") + expireDate.toLocaleString("pt") + "."
                );
                this.paymentMethodValueClone.value = 0;
              } else if (voucherData.FaceValueAmount - voucherData.SpentValueAmount <= 0) {
                this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgUsedVoucher"));
                this.paymentMethodValueClone.value = 0;
              } else {
                this.paymentMethodValueClone.value = parseFloat(
                  Math.min(
                    voucherData.FaceValueAmount - voucherData.SpentValueAmount,
                    this.localPaymentMethodValue
                  ).toFixed(2)
                );
              }
            } else {
              this.paymentMethodValueClone.value = 0;
              this.paymentMethodValueClone.voucherCode = null;

              this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgInvalidSlip"));
            }
            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            this.paymentMethodValueClone.value = 0;
            this.paymentMethodValueClone.voucherCode = null;
            getErrorMessage(error, "Getting Sale Search Results", this);
          }
        );
      }
    },
    submitSaleSearch: function (e) {
      if (e.keyCode === 13) {
        this.applySaleSearch();
      }
    },
    addPaymentMethod() {
      this.$emit("addPaymentMethod");
    },
    removePaymentMethod() {
      this.$emit("removePaymentMethod", this.index);
    },
    changedPM() {
      if (this.isEmitVoucher && this.cartType !== "return") {
        this.paymentMethodValueClone.value = 0;
        this.paymentMethodValueClone.voucherCode = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
    /**
     *
     * @modified ana.castro 2021.09.14 SABO-2147
     **/
    paymentMethodsMap: function () {
      let pmm = {};
      for (let o of this.paymentMethods) {
        pmm[o["id"]] = o["data"];
      }
      return pmm;
    },
    isEmitVoucher: function () {
      return (
        this.paymentMethodValueClone.method &&
        this.paymentMethodsMap[this.paymentMethodValueClone.method] &&
        this.paymentMethodsMap[this.paymentMethodValueClone.method]["EmitTransactionVoucher"] &&
        this.paymentMethodsMap[this.paymentMethodValueClone.method]["EmitTransactionVoucher"] === 1
      );
    },
  },
  watch: {
    paymentMethodValue() {
      // Changes in parent must remove the child key
      if (!this.paymentMethodValue.child) {
        this.paymentMethodValueClone = JSON.parse(JSON.stringify(this.paymentMethodValue));
      }
    },
    paymentMethodValueClone: {
      handler() {
        this.paymentMethodValueClone.child = true;
        this.$emit("modifyPaymentMethodValue", {
          index: this.index,
          value: this.paymentMethodValueClone,
        });
        this.$emit("modifyChangePaymentMethodValue", {
          index: this.index,
          value: this.paymentMethodValueClone,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.localPaymentMethodValue = this.paymentMethodValue.value;
  },
};
</script>

<style scoped></style>
